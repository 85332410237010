<template>
  <v-app-bar class="toolbar" flat app color="white">
    <v-app-bar-nav-icon class="d-lg-none" @click="$emit('showSidebar')" />
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <v-btn color="primary" outlined class="d-flex ma-4" href="mailto:support@owqlohelp.zendesk.com">
      <v-icon left>
        support_agent
      </v-icon>
      {{ $t('user.contactSupport') }}
    </v-btn>
    <v-menu bottom left offset-y nudge-bottom="7">
      <template #activator="{ on }">
        <v-btn icon fab v-on="on">
          <v-icon>translate</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="locale in locales" :key="locale.code" @click="changeLocale(locale.code)">
          <v-list-item-title>{{ locale.language }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn icon class="mr-2" @click="$router.push({ name: 'logout' })">
      <v-icon>power_settings_new</v-icon>
    </v-btn>
    <v-dialog v-model="editUserDialog" max-width="700">
      <template #activator="{ on, attrs }">
        <div class="user-info" v-bind="attrs" v-on="on">
          <Avatar :url="userAvatar" :name="`${userFirstName} ${userLastName}`" fixed-color class="mr-2" />
          {{ `${userFirstName} ${userLastName}` }}
        </div>
      </template>
      <v-card class="pa-16 card text-center" elevation="18">
        <EditUser @close-dialog="editUserDialog = false" />
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import localeUtils from '@/utils/mixins/localeUtils'

export default {
  name: 'Header',
  components: {
    EditUser: () => import('@/modules/user/EditUser'),
    Avatar: () => import('@/components/Avatar'),
  },
  mixins: [localeUtils],
  data() {
    return {
      editUserDialog: false,
    }
  },
  computed: {
    title() { return this.$route.meta.headerTitle() },
    userFirstName() { return this.$store.state.user.dbData.firstName },
    userLastName() { return this.$store.state.user.dbData.lastName },
    userAvatar() { return this.$store.state.user.dbData.avatar },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
